<template>
  <CContainer class="d-flex align-items-center min-vh-100 pt-2">
    <CRow class="justify-content-center">
      <CCol md="11">
        <CCard>
          <CCardHeader><h2>Terms and Conditions</h2></CCardHeader>
          <CCardBody>

            <p >Welcome to the web site of
              Deluxe Branded Marketing. Use of this site, and purchase of products and
              services from us through this site, are subject to the terms and conditions
              contained in the Web Site User’s Agreement set forth below (the “WSUA”). In
              continuing to access or use our web site, you agree to be bound by the WSUA</p>

            <p >Deluxe Branded Marketing’s
              License to You</p>

            <p >Deluxe Branded Marketing grants
              you a single, non-exclusive, non-transferable and limited personal license to
              access and use our web site. This license is conditioned on your continued
              compliance with the terms and conditions in this WSUA.</p>

            <h5 >Use of Our Web Site</h5>

            <p >You agree to the following in
              connection with your use of our web site:</p>

            <ul type=disc>
              <li  style='color:#3C3C3C'>You will use our web site in a
                manner that is consistent with all applicable laws and regulations, broker
                “house rules”, and the rules and regulations of any and all professional
                or trade organizations of which you are a member, and in accordance with
                the terms and conditions of this WSUA;</li>
              <li  style='color:#3C3C3C'>You will not upload any information
                or other material protected by copyright or any other intellectual
                property right (as well as rights of publicity and privacy) without first
                obtaining the permission of the owner of such rights;<span
                    style='font-family:"MS Gothic"'>&#8232;</span></li>
              <li  style='color:#3C3C3C'>You will not upload, email or otherwise
                transmit any material that contains viruses or any other computer code,
                files or programs which might interrupt, limit or interfere with the
                functionality of any computer software or hardware or telecommunications
                equipment;</li>
              <li  style='color:#3C3C3C'>You will not upload any information
                or other material for any purpose that is unlawful, tortious, abusive,
                intrusive on another’s privacy, harassing, libelous, defamatory,
                embarrassing, obscene, threatening or hateful; and&nbsp;<span
                    style='font-family:"MS Gothic"'>&#8232;</span></li>
              <li  style='color:#3C3C3C'>You are solely responsible for the
                content of all products that you order from Deluxe Branded Marketing, and
                acknowledge and agree that Deluxe Branded Marketing will not review or
                screen any such content for compliance with any applicable laws or
                regulations, broker “house rules”, or the rules and regulations of any
                professional or trade organization.</li>
            </ul>

            <h5 >Registration Information</h5>

            <p >When you register to use our web
              site, we may ask you to give us certain identifying information
              (“Registration”). You agree to provide true, accurate, current and complete
              information about yourself. You also agree not to impersonate any person or
              entity, misrepresent any affiliation with another person, entity or
              association, or otherwise conceal your identity from Deluxe Branded Marketing
              for any purpose.</p>

            <p >For your protection and the protection
              of our other web site users, we ask you not to share your Registration
              information (including password and/or user name) with any other person for the
              purpose of facilitating their access or use of our web site. You alone are
              responsible for all transactions initiated, or acts or omissions that occur, on
              or through our web site through the use of your Registration information.</p>

            <h5 >Unauthorized Use of Your
              Registration Information</h5>

            <p >If you believe that someone has
              used your Registration information to access our web site without your
              authorization, please call Deluxe Branded Marketing immediately at (949)
              348-2292.</p>

            <h5 >Broker Access to Registration
              and Other Information</h5>

            <p >You acknowledge and agree that
              the broker with which you are affiliated when you register to use our web site
              will have access to your Registration information (including your password and
              user name), as well as access to all data and information (including mailing
              lists) that you upload on to our web site. You agree that Deluxe Branded
              Marketing will not be liable to you in any way as the result of your broker’s
              access to or use of your Registration information (including your password and
              user name), or any data or information (including mailing lists) that you
              upload on to our web site.</p>

            <h5 >Prices; Quantities of Products
              Ordered</h5>

            <p >Prices for products and services
              ordered by you through our web site are subject to change from time to time
              without notice. The quantity of products ordered and paid for by you may exceed
              the quantity of products actually mailed by us due to the elimination, prior to
              mailing, of duplicate addresses, addresses subject to “no mail” requests, and
              other reasons, and you will not be entitled to a refund or credit with respect
              to any order when this occurs.</p>

            <h5 >Delivery of Products</h5>

            <p >Deluxe Branded Marketing will
              use commercially reasonable efforts to deliver each order placed by you to the
              United States Postal Service (“USPS) or other third party carrier, as specified
              by you when the order is placed, within Deluxe Branded Marketing’s stated lead
              time. However, you agree that in no event will Deluxe Branded Marketing be
              liable to you or any third party in any way for any delay in doing so. Further,
              you agree that in no event will Deluxe Branded Marketing be responsible for any
              failure or delay in the delivery of any order caused by the USPS or other third
              party carrier.</p>

            <h5 >Third Party Data</h5>

            <p >We may make available to you
              through our web site data and information that we obtain from one or more third
              parties (“Third Party Service Providers”). This data and information
              (collectively, “Third Party Data”) includes names, addresses and other data and
              information that you may elect to use for the purpose of mailing products
              ordered by you from us. Deluxe Branded Marketing does not endorse or approve Third
              Party Data, and we make it available to you only as a convenience to you.
              Deluxe Branded Marketing and our Third Party Service Providers do not (a)
              guarantee the accuracy or completeness of Third Party Data; or (b) warrant any
              results from your use of Third Party Data. We may discontinue offering Third
              Party Data, change the Third Party Data available to you, or change the Third
              Party Service Provider(s) from whom Third Party Data is obtained, at any time
              without notice. You agree that neither Deluxe Branded Marketing nor the Third
              Party Service Providers will be liable to you or any third party in any way for
              the termination, interruption, delay, or inaccuracy of any Third Party Data.</p>

            <h5 >Limitations on Your Use of Third
              Party Data</h5>

            <p >Your right to use the Third
              Party Data is limited to your personal use solely for the purpose of mailing
              products purchased by you from Deluxe Branded Marketing, and you will use the
              Third Party Data for no other purpose. Without limiting the generality of the
              preceding sentence, in no event will you publish, broadcast, distribute or
              otherwise provide any Third Party Data to any other person or entity, or
              provide any other person or entity access to any Third Party Data. Further, you
              will use the Third Party Data in strict compliance with all applicable federal,
              state and local laws, rules and regulations, including but not limited to those
              concerning direct marketing.</p>

            <h5 >&quot;No Mail&quot; Requests</h5>

            <p >Deluxe Branded Marketing will
              use commercially reasonable efforts to comply with “no mail” requests of which
              it has been given notice. However, you agree that Deluxe Branded Marketing will
              not be liable in any way to you or any third party as the result of Deluxe
              Branded Marketing’s failure to comply with any “no mail” request.</p>

            <h5 >Revisions</h5>

            <p >Deluxe Branded Marketing may
              revise the WSUA at any time, and you agree to be bound by future revisions. It
              is your responsibility to visit the link at the bottom of the www.re.deluxe.com
              home page periodically to review the most current terms and conditions.</p>

            <h5 >Notices, Communications, and
              Electronic Signatures</h5>

            <p >You agree to accept all
              communications from us regarding use of our web site at the addresses you
              provide during Registration. Please promptly update any changes to your
              Registration information. Deluxe Branded Marketing is entitled to rely on the
              email address and U.S. mail address that you last provided to us. You agree to
              waive all claims resulting from failure to receive communications because of
              changes in your email or U.S. mail address.</p>

            <p >You agree to be bound by any
              affirmation, assent or agreement you transmit electronically through our web
              site. You agree that, when in the future you click on an “I agree,” “I consent”
              or other similarly worded “button” or entry field with your mouse, keystroke or
              other device, your agreement or consent will be legally binding and enforceable
              and the legal equivalent of your handwritten signature.</p>

            <h5 >Monitoring By Deluxe Branded
              Marketing</h5>

            <p >Deluxe Branded Marketing, its
              affiliates and agents are entitled, but not obligated, to review or retain your
              communications through our web site. We and our affiliates and agents may
              monitor those communications to evaluate the quality of service you receive,
              your compliance with this WSUA, the security of our web site, or for other
              reasons. You agree that these monitoring activities will not entitle you to any
              cause of action or other right with respect to the manner in which Deluxe
              Branded Marketing or its affiliates or agents monitor your communications and
              enforce or fail to enforce the terms of this WSUA. You agree that in no event
              will Deluxe Branded Marketing or its affiliates or agents be liable for any
              costs, damages, expenses or any other liabilities incurred by you as a result
              of any monitoring activities.</p>

            <h5 >Disclaimer of Warranties</h5>

            <p >DELUXE BRANDED MARKETING MAKES
              NO WARRANTIES REGARDING OUR WEB SITE OR ANY PRODUCTS OR SERVICES THAT YOU MAY
              PURCHASE FROM DELUXE BRANDED MARKETING. DELUXE BRANDED MARKETING AND ITS
              AFFILIATES AND AGENTS (INCLUDING THIRD PARTY SERVICE PROVIDERS) DISCLAIM ALL
              WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
              WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.</p>

            <p >SOME STATES DO NOT ALLOW
              LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY
              NOT APPLY TO YOU. YOU MAY HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE.</p>

            <h5 >Indemnification</h5>

            <p >YOU AGREE TO INDEMNIFY AND HOLD
              DELUXE BRANDED MARKETING AND ITS AFFILIATES, AGENTS, EMPLOYEES, AND LICENSORS
              (INCLUDING THE THIRD PARTY SERVICE PROVIDERS) HARMLESS FROM ANY CLAIM, DEMAND,
              LOSS, COSTS OR EXPENSE, INCLUDING ATTORNEYS’ FEES, SUFFERED OR INCURRED BY
              DELUXE BRANDED MARKETING AND/OR ITS AFFILIATES, AGENTS, EMPLOYEES, AND/OR
              LICENSORS (INCLUDING THE THIRD PARTY SERVICE PROVIDERS), ARISING DIRECTLY OR
              INDIRECTLY OUT OF YOUR VIOLATION OF THIS AGREEMENT, OR ANY OTHER PERSON’S
              RIGHTS, INCLUDING BUT NOT LIMITED TO INFRINGEMENT OF ANY COPYRIGHT OR VIOLATION
              OF ANY PROPRIETARY OR PRIVACY RIGHT.</p>

            <h5 >Limitation of Liability</h5>

            <p >IN NO EVENT WILL DELUXE BRANDED
              MARKETING HAVE ANY LIABILITY OR OBLIGATION TO YOU WITH RESPECT TO ANY PRODUCTS
              OR SERVICES PURCHASED BY YOU FROM DELUXE BRANDED MARKETING IN AN AGGREGATE
              AMOUNT GREATER THAN THE AMOUNT PAID BY YOU FOR THE ORDER IN QUESTION, LESS THE
              AGGREGATE POSTAGE INCLUDED IN THAT PRICE.</p>

            <p >UPLOADING BY YOU OF ANY DATA OR
              MATERIAL ON TO OUR WEB SITE IS DONE AT YOUR OWN DISCRETION AND RISK. YOU ARE
              SOLELY RESPONSIBLE FOR TAKING ALL NECESSARY BACKUP PROCEDURES, AND DELUXE
              BRANDED MARKETING WILL IN NO EVENT BE RESPONSIBLE FOR ANY LOSS OF ANY SUCH DATA
              OR MATERIAL.</p>

            <p >UNDER NO CIRCUMSTANCES WILL
              DELUXE BRANDED MARKETING OR ITS AFFILIATES, AGENTS, EMPLOYEES, OR LICENSORS
              (INCLUDING THIRD PARTY SERVICE PROVIDERS) HAVE ANY LIABILITY OR OBLIGATION TO
              YOU AND/OR ANY THIRD PARTY FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
              INTANGIBLE LOSSES, OR ANY DIRECT OR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES, EVEN IF DELUXE BRANDED MARKETING HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, THAT, DIRECTLY OR INDIRECTLY, RESULT FROM OR ARISE
              OUT OF (A) ANY PRODUCT OR SERVICE PURCHASED FROM DELUXE BRANDED MARKETING OR
              ANY DELAY IN THE DELIVERY OF ANY SUCH PRODUCT OR SERVICE; (B) THE USE OF ANY
              THIRD PARTY DATA; OR (C) THE USE OF, OR THE INABILITY TO USE, OUR WEB SITE,
              WHETHER ARISING OUT OF BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY IN
              TORT, OR ANY OTHER CAUSE OF ACTION. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION
              OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

            <p >Because some states prohibit the
              limitation of liability for consequential or incidental damages, in such states
              the limitation of liability (only with respect to consequential or incidental
              damages) may not apply to you, and the respective liability of Deluxe Branded
              Marketing and its affiliates, agents, employees and licensors (including Third
              Party Service Providers) is limited to the greatest extent allowable under
              applicable law in those states. In the event that a court or arbitration panel,
              as the case may be, should hold that the limitations of liabilities or remedies
              available as set forth in this WSUA are unenforceable for any reason, or that
              any of your remedies under this WSUA fail, then you expressly agree that under
              no circumstances will the total, aggregate liability of Deluxe Branded
              Marketing and its affiliates, agents, employees and licensors (including Third
              Party Service Providers), to you or any party claiming by or through you for
              any cause whatsoever exceed $1000, regardless of the form of action and whether
              in contract, statute, tort or otherwise.</p>

            <h5 >Trademarks and Copyrights</h5>

            <p >The Deluxe Branded Marketing web
              site is owned by Deluxe Branded Marketing and all material and content
              displayed on the web site is owned by Deluxe Branded Marketing or its
              affiliates, agents or licensors (including the Third Party Service Providers)
              and is protected by United States copyright laws and international treaty
              provisions. All content, trademarks, services marks, trade names, logos, and
              icons are proprietary to Deluxe Branded Marketing or its affiliates, agents or
              licensors (including Third Party Service Providers). Other third-party products
              and brand names may be trademarks, service marks or trade names of their
              respective owners, and may not be affiliated with Deluxe Branded Marketing.
              Nothing contained on the Deluxe Branded Marketing web site should be construed
              as granting, by implication, estoppel, or otherwise, any license or right to
              use any trademark, service mark or trade name displayed on the Deluxe Branded
              Marketing web site without the written permission of Deluxe Branded Marketing
              or such third party that may own such trademarks, service marks or trade names.</p>

            <h5 >Modifications, Suspensions and
              Terminations of Access to Deluxe Branded Marketing’s Web Site</h5>

            <p >Deluxe Branded Marketing
              reserves the right to modify or discontinue, temporarily or permanently, our
              web site (or any part thereof) without notice. You agree that Deluxe Branded
              Marketing will not be liable to you or to any third party for any modification,
              suspension or discontinuance of our web site. The license granted under this
              WSUA will terminate if Deluxe Branded Marketing believes that any information
              provided by you, including your email address, is no longer current or
              accurate, if you fail to otherwise comply with any term or condition of this
              WSUA, or if you fail to pay, when due, any amount owed by you to Deluxe Branded
              Marketing.</p>

            <h5 >Use of Access Devices</h5>

            <p >With the exception of
              applications commonly known as web browser software, you agree not to use any
              software, program, application or any other device to access or log on to our
              web site.</p>

            <h5 >Security of Data Transmissions
              and Storage</h5>

            <p >Electronic (including wired and
              wireless) communications through our web site may not be encrypted. You
              acknowledge that there is a risk that data, including email, electronic and
              wireless communications and personal data, may be accessed by unauthorized
              third parties when communicated between you and Deluxe Branded Marketing.</p>

            <h5 >Governing Law; Jurisdiction and
              Venue</h5>

            <p >This WSUA will be governed by
              the law of the State of California, without regard to its conflicts of law
              principles. This is the case regardless of whether you reside or transact
              business with Deluxe Branded Marketing in California or elsewhere. You agree to
              submit to the personal and exclusive jurisdiction of the state and federal
              courts located within the County of Orange, California. If any part of this
              WSUA is unlawful, void or unenforceable, that part will be deemed severable and
              will not affect the validity and enforceability of any remaining provisions.</p>

          </CCardBody>
        </CCard>


      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'TermsOfUse'
}
</script>
